<template>
  <div class="section-save-money">
    <div class="container">
      <h2 class="header">
        Get connected to a local, expert
        contractor who can help you complete
        your dream home project
      </h2>
      <div class="fade-line"></div>
      <p class="text">
        Get connected to local solar power installation experts who can help you harness the energy of the sun to power your home, at an affordable price.
      </p>
      <div class="split-container">
        <div class="box right-box">
          <img src="../../assets/images/s1-img.jpeg" alt="s1-img" class="hero-img">
          <div class="text-container">
            <p class="info">
              80% of homes are at least 20 years old, and need remodeling
            </p>
            <p class="info">
              Finding applicable service providers is the number 1 challenge when coming to renovating
            </p>
            <p class="info">
              Renovation can help save money on insurance
            </p>
          </div>
        </div>
        <div class="box left-box">
          <ul class="list">
            <li>
              <div class="image-container">
                <img src="../../assets/images/solar-panel.svg" alt="solar panel">
              </div>
              <p>
                Solar Installation
              </p>
            </li>
            <li>
              <div class="image-container">
                <img src="../../assets/images/roofing-contractor.svg" alt="roofing-contractor">
              </div>
              <p>
                Roofing
              </p>
            </li>
            <li>
              <div class="image-container">
                <img src="../../assets/images/tile.svg" alt="tile" style="height: 25px">
              </div>
              <p>
                Siding
              </p>
            </li>
            <li>
              <div class="image-container">
                <img src="../../assets/images/redo.svg" alt="redo">
              </div>
              <p>
                Kitchen/Bathroom Remodel
              </p>
            </li>
            <li>
              <div class="image-container">
                <img src="../../assets/images/fire.svg" alt="fire">
              </div>
              <p>
                Water and Fire Restoration
              </p>
            </li>
            <li>
              <div class="image-container">
                <img src="../../assets/images/more.svg" alt="more">
              </div>
              <p>
                And many more!
              </p>
            </li>
          </ul>
        </div>
        <a href="/solar-savings" class="button">Get A Free Quote</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/style/main';
  .section-save-money {
    padding: 60px 0 70px;
    font-family: "Poppins", sans-serif;
    .container {
      .header {
        color: $dark-blue;
        font-size: 40px;
        line-height: 50px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 22px;
        max-width: 800px;
        margin: auto;
      }
      .fade-line {
        height: 1px;
        background-color: #E6E6E6;
        width: 80%;
        margin: 0 auto;
        background-image: linear-gradient(to left , white 2%, #E6E6E6 50%, white 98%);
        background-image: -o-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
        background-image: -moz-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
        background-image: -webkit-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
        background-image: -ms-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
        background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0.02, white), color-stop(0.5, gray), color-stop(0.98, white) );
      }
      .text {
        color: #161616;
        font-size: 19px;
        margin-top: 30px;
        letter-spacing: .5px;
        line-height: 28px;
        padding: 0 150px;
        text-align: center;
      }
      .split-container {
        width: 100%;
        height: auto;
        margin-top: 20px;
        .box {
          width: 50%;
        }
        .left-box {
          float: left;
          .list {
            padding: 0;
            li {
              list-style: none;
              height: 62px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              position: relative;
              padding-left: 70px;
              margin-top: 10px;
              .image-container {
                position: absolute;
                left: 0;
                top: 4px;
                width: 54px;
                height: 54px;
                background: #fff;
                border-radius: 50%;
                line-height: 48px;
                text-align: center;
                background: linear-gradient(180deg, #ff001a, #911f2b);
                -webkit-box-shadow: -4px 3px 5px 0 #0000004f;
                box-shadow: -4px 3px 5px 0 #0000004f;
                img {
                  display: inline-block;
                  vertical-align: middle;
                  height: 35px;
                  filter: invert(100%) sepia(83%) saturate(431%) hue-rotate(186deg) brightness(115%) contrast(100%);
                }
              }
              p {
                font-size: 23px;
                font-weight: 600;
                color: $dark-blue;
              }
            }
          }
        }
        .right-box {
          float: right;
          margin-top: 20px;
          position: relative;
          .hero-img {
            float: right;
            width: 61.2%;
            border-radius: 10px;
            margin-bottom: 15px;
          }
          .text-container {
            position: absolute;
            left: 30px;
            width: 70%;
            background: $dark-blue;
            border-radius: 10px;
            -webkit-box-shadow: -4px 3px 8px 0 #0000004f;
            box-shadow: -4px 3px 8px 0 #0000004f;
            padding: 20px 15px;
            top: 25px;
            z-index: 1;
            .info {
              margin-bottom: 40px;
              font-size: 21px;
              color: white;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        .button {
          background: -moz-linear-gradient(180deg, #ff001a, #911f2b);
          background: -webkit-linear-gradient(180deg, #ff001a, #911f2b);
          background: linear-gradient(180deg, #ff001a, #911f2b);
          border: none;
          font-size: 25px;
          font-weight: 500;
          border-radius: 5px;
          font-family: $font-poppins;
          color: white;
          outline: none;
          text-decoration: none;
          padding: 10px 20px;
          margin-top: 30px;
          float: right;
          position: relative;
          right: 39%;
          &:hover {
            background: -moz-linear-gradient(0deg, #ff001a, #911f2b);
            background: -webkit-linear-gradient(0deg, #ff001a, #911f2b);
            background: linear-gradient(0deg, #ff001a, #911f2b);
          }
        }
      }
    }
  }


@media only screen and (max-width: 1180px) {
  .section-save-money .container .split-container {
    .right-box {
      margin-top: 10px;
      .hero-img {
        min-height: 450px;
      }
    }
    .button {
      margin-top: 70px;
      right: -14%;
    }
  }
}

@media only screen and (max-width: 1021px) {
  .section-save-money {
    .container {
      .header {
        font-size: 34px;
        line-height: 42px;
      }
      .text {
        padding: 0;
        margin-top: 20px;
      }
      .split-container {
        .left-box .list li p {
          font-size: 20px;
        }
        .right-box {
          .text-container {
            width: 80%;
            top: 40px;
            .info {
              margin-bottom: 32px;
              font-size: 20px;
            }
          }
          .hero-img {
            min-height: 340px;
          }
        }
        .button {
          margin-top: 100px;
          right: 32%;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .section-save-money {
    padding: 30px 0 30px;
    .container {
      .header {
        font-size: 27px;
        line-height: 36px;
        background-size: 400px;
      }
      .text {
        font-size: 17px;
        line-height: 25px;
      }
      .split-container {
        .right-box {
          width: 100%;
          .hero-img {
            width: 100%;
            height: 400px;
            object-fit: cover;
            float: none;
          }
          .text-container {
            position: relative;
            min-width: calc(100% - 42px);
            left: 20px;
            top: 0;
            margin-top: -250px;
            margin-bottom: 20px;
            .info {
              font-size: 17px;
            }
          }
        }
        .left-box {
          width: 100%;
          .list {
            li {
              margin-left: 25px;
            }
          }
        }
        .button {
          margin-top: 30px;
          right: calc(50% - 125px);
        }
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .section-save-money .container .split-container {
    .right-box .text-container {
      margin-top: -300px;
    }
    .left-box .list li {
      margin-left: 15px;
    }
    .button {
      right: calc(50% - 117px);
      font-size: 23px;
    }
  }
}

@media only screen and (max-width: 300px) {
  .section-save-money .container .split-container .left-box .list li {
    margin-left: 5px;
  }
}
</style>