export const FACTS_LIST = [
  'Solar panels are virtually maintenance free, aside from occasional cleaning.',
  'Solar panels are the only home improvement that pays for itself.',
  'Solar panels may increase the value of your home by up to $25,000! This is like having extra money in the bank.',
  'Solar does not produce any air pollution.',
  'Solar energy is significantly cheaper than fossil fuels.',
  'The US is the 3rd largest solar energy market in the wold.'
]

export const MONTH_LIST = {
  '0': 'January',
  '1': 'February',
  '2': 'March',
  '3': 'April',
  '4': 'May',
  '5': 'June',
  '6': 'July',
  '7': 'August',
  '8': 'September',
  '9': 'October',
  '10': 'November',
  '11': 'December',
}

