import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'Open Home' },
    component: Home
  },
  {
    path: '/search-lander/',
    name: 'search-lander',
    meta: { title: 'Open Home' },
    component: () => import (/* webpackChunkName: "search-lander" */ '../views/SearchLander.vue')
  },
  {
    path: '/window-replacement/',
    name: 'window-replacement',
    meta: { title: 'Open Home' },
    component: () => import (/* webpackChunkName: "search-lander" */ '../views/SearchLander.vue')
  },
  {
    path: '/select-path/',
    name: 'select-path',
    meta: { title: 'Select Path' },
    component: () => import(/* webpackChunkName: "select-path" */ '../views/SelectPathView')
  },
  {
    path: '/solar-savings/',
    name: 'solar-savings',
    meta: { title: 'Calculate Savings' },
    component: () => import(/* webpackChunkName: "solar-savings" */ '../views/QuestionFlowSolarSavings')
  },
  {
    path: '/solar-savings/v2',
    name: 'solar-savings/v2',
    meta: { title: 'Calculate Savings' },
    component: () => import(/* webpackChunkName: "solar-savings" */ '../views/QuestionFlowSolarSavings')
  },
  {
    path: '/windows/',
    name: 'windows',
    meta: { title: 'Calculate Savings' },
    component: () => import(/* webpackChunkName: "solar-savings" */ '../views/QuestionFlowWindows')
  },
  {
    path: '/windows/v2',
    name: 'windows/v2',
    meta: { title: 'Calculate Savings' },
    component: () => import(/* webpackChunkName: "solar-savings" */ '../views/QuestionFlowWindows')
  },
  {
    path: '/flooring/',
    name: 'flooring',
    meta: { title: 'Calculate Savings' },
    component: () => import(/* webpackChunkName: "solar-savings" */ '../views/QuestionFlowFlooring')
  },
  {
    path: '/flooring/v2',
    name: 'flooring/v2',
    meta: { title: 'Calculate Savings' },
    component: () => import(/* webpackChunkName: "solar-savings" */ '../views/QuestionFlowFlooring')
  },
  {
    path: '/remodel/',
    name: 'remodel',
    meta: { title: 'Calculate Savings' },
    component: () => import(/* webpackChunkName: "solar-savings" */ '../views/QuestionFlowRemodel')
  },
  {
    path: '/remodel/v2',
    name: 'remodel/v2',
    meta: { title: 'Calculate Savings' },
    component: () => import(/* webpackChunkName: "solar-savings" */ '../views/QuestionFlowRemodel')
  },
  {
    path: '/roofing/',
    name: 'roofing',
    meta: { title: 'Calculate Savings' },
    component: () => import(/* webpackChunkName: "solar-savings" */ '../views/QuestionFlowRoofing')
  },
  {
    path: '/roofing/v2',
    name: 'roofing/v2',
    meta: { title: 'Calculate Savings' },
    component: () => import(/* webpackChunkName: "solar-savings" */ '../views/QuestionFlowRoofing')
  },
  {
    path: '/terms-and-conditions/',
    name: 'terms-and-conditions-you',
    meta: { title: 'Terms and Conditions' },
    component: () => import(/* webpackChunkName: "terms-and-conditions-you" */ '../views/TermsAndConditions')
  },
  {
    path: '/privacy-policy/',
    name: 'privacy-policy',
    meta: { title: 'Privacy Policy' },
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy')
  },
  {
    path: '/ccpa/',
    name: 'ccpa-privacy-policy',
    meta: { title: 'CCPA Privacy Policy' },
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/CCPAPrivacyPolicy')
  },
  {
    path: '/marketing-partners/',
    name: 'marketing-partners',
    meta: { title: 'Marketing Partners' },
    component: () => import(/* webpackChunkName: "marketing-partners" */ '../views/MarketingPartners')
  },
  {
    path: '/do-not-sell-my-information/',
    name: 'DoNotSellMyInformation',
    meta: { title: 'DoNotSellMyInformation' },
    component: () => import(/* webpackChunkName: "DoNotSellMyInformation" */ '../views/DoNotSellMyInformation.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    meta: { title: "404" },
    component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
