import axios from 'axios'
import {
  checkHowManyFlowsAreVisited,
  extractAllUrlParamsAndSetThemInVuexStore, setEverFlow,
} from '@/js/utils'
import {format} from "date-fns";

const remodelFlow = {
    namespaced: true,
    actions: {
        goToNextStep({rootState, getters, commit, dispatch}, step) {
            let nextStep = ''

            switch(step) {
                case 'StartPath': {
                    commit('SET_PROGRESS_BAR_TO_DEFAULT', null, {root: true})
                    commit('SET_VISITED_FLOW', 'remodel', {root: true})
                    commit('SET_CURRENT_FLOW', 'remodel', {root: true})
                    commit('SET_ORIGIN_PATH', null, {root: true})

                    if (checkHowManyFlowsAreVisited(rootState.visitedFlows) === 1) {
                        //extract all URL params and set them as fields in Vuex Store
                        commit('SET_ARRAY_OF_FIELDS', extractAllUrlParamsAndSetThemInVuexStore(76), {root: true})
                    }

                  const urlParams = new URLSearchParams(location.search)

                  if (urlParams.has('show-final-step') && urlParams.get('show-final-step') === '1' ) {
                    commit('SET_ALL_FLOWS_AS_VISITED', null, { root: true })
                    nextStep = 'SharedThankYouWithMultiplePaths'
                    break
                  }

                  if (!urlParams['ef_transaction_id']) {
                    setEverFlow(rootState.fields.oid || 76)
                      .then(({ef_transaction_id}) => {
                        commit('SET_FIELD', { field: 'ef_transaction_id', value: ef_transaction_id}, {root: true})
                      })
                  }

                    if (!rootState.fields['zip_code'] ||
                        !rootState.fields['state'] ||
                        !rootState.fields['county'] ||
                        !rootState.fields['city']) {
                            nextStep = 'SharedQuestionZip'
                            break
                    }

                    nextStep = 'RemodelQuestionWhatWouldYouHaveRemodeled'
                    break
                }

                case 'SharedQuestionZip': {
                    nextStep = 'RemodelQuestionWhatWouldYouHaveRemodeled'
                    break
                }

                case 'RemodelQuestionWhatWouldYouHaveRemodeled': {
                    nextStep = 'SharedQuestionWhenWouldYouLikeToCompleteThisProject'
                    break
                }

                case 'SharedQuestionWhenWouldYouLikeToCompleteThisProject': {
                    if (!rootState.fields['first_name'] ||
                        !rootState.fields['last_name'] ||
                        !rootState.fields.email) {
                            nextStep = 'SharedQuestionFirstAndLastName'
                            break
                    }

                    dispatch('postData')

                    if (checkHowManyFlowsAreVisited(rootState.visitedFlows) === 5) {
                        dispatch('tapStoneLinkOut', null, {root:true})
                    }

                    nextStep = 'SharedThankYouWithMultiplePaths'
                    break
                }

                case 'SharedQuestionFirstAndLastName': {
                    nextStep = 'SharedQuestionAddressAndPhone'
                    break
                }

                case 'SharedQuestionAddressAndPhone': {
                    dispatch('postData')

                    if (checkHowManyFlowsAreVisited(rootState.visitedFlows) === 5) {
                        dispatch('tapStoneLinkOut', null, {root:true})
                    }

                    nextStep = 'SharedThankYouWithMultiplePaths'
                    break
                }
            }

            commit('SET_PROGRESS_BASED_ON_NUMBER_OF_CASES', 5, { root: true })
            commit('SET_CURRENT_STEP', nextStep, { root: true })
        },
        async postData({ rootState, getters, commit, dispatch }, data) {
            const urlParams = new URLSearchParams(location.search)
            // urlParams.delete('ef_transaction_id')
            if (urlParams.has('oid')) {
              urlParams.delete('oid')
            }
            history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())

            const fieldsSaved = rootState.fields
            let postData = data

            const both_tcpa_compliance_present = fieldsSaved.jornaya_id && fieldsSaved.trusted_form_cert_id ? 'yes' : 'no'
            const both_tcpa_compliance_missing = !fieldsSaved.jornaya_id && !fieldsSaved.trusted_form_cert_id ? 'yes' : 'no'

            postData = {
                ...fieldsSaved,
                page_referer: 'remodel-improvement',
                oid: fieldsSaved.oid || 76,
                sub_id: fieldsSaved.sub_id || '1',
                sub_id2: fieldsSaved.sub_id2 || '',
                sub_id3: fieldsSaved.sub_id3 || '',
                sub_id4: fieldsSaved.sub_id4 || '',
                affid: fieldsSaved.affid || '1',
                homeowner: 'Yes',
                home_improvement_project: 'Remodel',
                birth_date: format(new Date(1982, 4, 1), 'yyyy/MM/dd'),
                both_tcpa_compliance_present,
                both_tcpa_compliance_missing,
                current_flow: rootState.currentFlow,
                project: 'open_home'
            }

            commit('SET_TEMP_EF_ID', fieldsSaved.ef_transaction_id, {root: true})

            // delete  fieldsSaved.ef_transaction_id
            delete  fieldsSaved.oid

            // console.log('DataRemodelFlow: ', postData)
            //once all promises have been resolved, we continue to the thank you page regardless if any of these fail
            Promise.allSettled([
                axios.post('https://control.org31415.dev/api/leads/ingest', postData)
            ]).catch(e => {
                console.log('error:', e)
            });
        }
    }
}

export default remodelFlow
