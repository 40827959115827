import axios from "axios";

//valid front end email regex
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export async function ipqsCheckIfPhoneIsValid(phone) {
  //ipqs phone validation only accepts formatted numbers with country code in front
  const formattedNumber = phone.replace(/[()\-\s]+/g, "")
  const url = 'https://validations.org31415.dev/api/validations/ipqs/phone'

  const response = {
    valid: false,
    fullValid: false
  }

  try {
    const responseFromIpqs = await axios.post(url, { phone: formattedNumber })
    response.valid = responseFromIpqs.data.valid
    response.fullValid = responseFromIpqs.data.valid || responseFromIpqs.data.fraud_score > 85 || responseFromIpqs.data.line_type === 'Toll Free';
  } catch (err) {
    console.log(err)
    return response
  }

  return response
}

export const ipqsCheckIfEmailInvalid = async (email) => {
  let url = 'https://validations.org31415.dev/api/validations/ipqs/email'
  let response = false

  try {
    const respFromIpqs = await axios.post(url, { email })
    response = !respFromIpqs.data.valid || respFromIpqs.data.disposable || respFromIpqs.data.fraud_score > 90
  } catch (err) {
    console.log(err)
    return response
  }

  return response
}

export const zipLookup = async (zipCode) => {
  return await axios
    .get(`https://zip.org31415.dev/us/${zipCode}`)
    .then(response => {
      return {
        city: response.data.city,
        state: response.data.state,
        state_long: response.data.state_long,
        county: response.data.county,
        zip: response.data.zip
      }
    })
    .catch(e => {
      console.log('zipLookup error:', e)
      return {
        city: '',
        state: '',
        county: '',
        state_long: '',
        zip: ''
      }
    })
}

export const getIpAddress = async () => {
  let ip = '';
  try {
    const r = await fetch("https://api.ipify.org?format=json")
    const response = await r.json()
    ip = response.ip
  } catch (e) {
    const r = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
    const response = await r.text()
    ip = response.match(/ip=([^\n]+)/)[1]
  }
  return ip
}

export const getUserAgent = () => {
  return navigator.userAgent
}

export const getPageReferrer = () => {
  return window.location.pathname.replace(/\//g, '')
}

export const getLeadSource = () => {
  const r = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1][Math.floor(Math.random() * 20)]
  return ['google', 'facebook'][r]
}

export function extractUrlParams() {
  const urlSearchParams = new URLSearchParams(location.search)

  const obj = {}

  urlSearchParams.forEach((value, key) => {
    switch (key) {
      case 's1':
        obj['sub_id'] = checkForPlaceholder(value) || '1'
        break
      case 'sub2':
        obj['sub_id2'] = checkForPlaceholder(value)
        break
      case 'sub3':
        obj['sub_id3'] = checkForPlaceholder(value)
        break
      case 'sub4':
        obj['sub_id4'] = checkForPlaceholder(value)
        break
      default:
        obj[key] = checkForPlaceholder(value)
    }
  })

  if ('sub1' in obj) {
    obj['sub_id'] = checkForPlaceholder(obj['sub1']) || '1'
  }

  if ('sub5' in obj) {
    obj['click_id'] = checkForPlaceholder(obj['sub5']) || ''
    delete obj.sub5
  }

  return obj;
}

//check for placeholder from url params, i.e {first_name} is a placeholder, John (without curly brackets) would be okay
export const checkForPlaceholder = text => /^{.+}$/g.test(text) ? '' : text

export function extractAllUrlParamsAndSetThemInVuexStore(defaultOid= 61) {

  const paramsFromUrl = extractUrlParams()
  let oid = defaultOid

  if ('oid' in paramsFromUrl) {
    oid = paramsFromUrl.oid
  }

  if ('zip_code' in paramsFromUrl) {
    delete paramsFromUrl.zip_code
  }

  if ('email' in paramsFromUrl) {
    delete paramsFromUrl.email
  }

  const setAnswersFormUrl = [
    {field: 'oid', value: oid},
  ]

  //get any params from the url and set them as fields in vuex store
  for (const [key, value] of Object.entries(paramsFromUrl)) {
    setAnswersFormUrl.push({field: key, value: value})
  }

  return setAnswersFormUrl
}

export function setEverFlow(oid = 61) {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (window.EF) {
        const urlParams = new URLSearchParams(window.location.search)
        const tid = urlParams.get('ef_transaction_id')

        if (tid) {
          urlParams.set('ef_transaction_id', tid)
          history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
          resolve({ ef_transaction_id : tid })
          clearInterval(interval)
          return
        }

        EF.click({
          offer_id: oid,
          affiliate_id: EF.urlParameter('affid') || 1,
          transaction_id: EF.urlParameter('ef_transaction_id'),
          sub1: EF.urlParameter('sub1') || '',
          sub2: EF.urlParameter('sub2') || '',
          sub3: EF.urlParameter('sub3') || '',
          sub4: EF.urlParameter('sub4') || '',
          sub5: EF.urlParameter('sub5') || '',
        }).then(tid => {
          if (tid) {
            urlParams.set('ef_transaction_id', tid)
            history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
            resolve({ ef_transaction_id : tid })
          }
        })
        clearInterval(interval)
      }
    }, 5)
  })
}


export const getJornayaLeadIdToken = () => {
  return new Promise(res => {
    const i = setInterval(() => {
      const input = document.getElementById("leadid_token")
      let leadid_token = ''
      if (input) {
        leadid_token = input.value
      }
      if (leadid_token) {
        clearInterval(i)
        res(leadid_token)
      }
    }, 50)
  })
}

export const getTrustedForm = () => {
  let trustedFormUrl = '';
  let trustedFormId = '';
  if (document.querySelector('[name="xxTrustedFormToken"]')) {
    const tfCertUrl = document.querySelector('[name="xxTrustedFormToken"]').value
    const tfParts = tfCertUrl.split("https://cert.trustedform.com/");
    trustedFormUrl = tfCertUrl;
    trustedFormId = tfParts[1];
  }
  return {
    trustedFormUrl,
    trustedFormId
  }
}

export const getTrustedFormByPromise = () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const trustedForm = getTrustedForm()
      if (trustedForm.trustedFormUrl.length > 0) {
        clearInterval(interval)
        resolve(trustedForm)
      }
    }, 5)
  })
}

export const twilioPhonePost = async (phone) => {
  const url = 'https://proxy.leadprosper.tech/two-step-verification/phone'
  let response = {}

  try {
    const respFromReq = await axios.post(url, phone)
    response = respFromReq.data

  } catch (err) {
    console.log(err)
    return { sent: false }
  }


  return response
}

export const twilioCodePost = async (code) => {
  const url = 'https://proxy.leadprosper.tech/two-step-verification/code'
  let response = false

  try {
    const respFromReq = await axios.post(url, code)
    response = respFromReq.data.status === 'approved'
  } catch (err) {
    console.log(err)
  }

  return response
}

export const checkHowManyFlowsAreVisited = (visitedFlows) => {
  return Object.values(visitedFlows).filter(flowValue => flowValue === true).length
}

export function sanitizePhone(phone) {
  if (!phone) return ''
  return phone.replace(/[()\-\s]+/g, "")
}
