<script setup>
import { reactive, watch } from 'vue'
import { zipLookup } from '@/js/utils'
import { useStore } from 'vuex'
import router from '../../router'

const store = useStore()
const state = reactive({
  buttonValue: '',
  zipCodeValue: '',
  city: '',
  state: '',
  county: '',
  zipError: false,
  animation: false,
})

watch(() => [state.zipCodeValue], () => {
  if (state.zipCodeValue.length > 0) state.zipError = false
})

async function zipValidation() {
  state.buttonValue = 'Loading ...'

  if (state.zipCodeValue.length < 5 || state.zipError) {
    state.zipError = true
    addAnimationAndReset()
    state.buttonValue = ''
    return
  }

  const zipData = await zipLookup(state.zipCodeValue)

  state.zipError = !zipData.zip;

  if (state.zipError) {
    addAnimationAndReset()
    state.buttonValue = ''
    return
  }

  if (!state.zipError) {
    state.city = zipData.city
    state.state = zipData.state
    state.county = zipData.county
  }

  state.buttonValue = ''

  setFields()
  // show select path question
  goToSelectPath()
}

function setFields() {
  store.commit('SET_ARRAY_OF_FIELDS', [
    { field: 'zip_code', value: state.zipCodeValue },
    { field: 'city', value: state.city },
    { field: 'state', value: state.state },
    { field: 'county', value: state.county }
  ])
}

function addAnimationAndReset() {
  // Reset the shake animation
  if (state.zipError === true) {
    state.animation = true
    setTimeout(() => {
      state.animation = false
    }, 1000)
  }
}

function goToSelectPath() {
  const params = new URLSearchParams(location.search)
  const queryParams = Object.fromEntries(params.entries());
  router.push({ path: 'select-path', query: queryParams })
}

</script>

<template>
  <div class="hero">
    <div class="container">
      <h1 class="hero-header">Find the right home improvement<br class="hero-break"/>contractor for your home</h1>
      <div id="calculate-savings" class="hero-savings">
        <div class="savings-top-box">
          <p class="top-text">Start here to receive a real time, exclusive quote</p>
          <p class="bottom-text">Find qualified and trusted contractors in your area</p>
        </div>
        <div class="savings-bottom-box">
          <div class="top-box">
            <input
                :disabled="state.buttonValue === 'Loading ...'"
                v-model="state.zipCodeValue"
                @keyup.enter="zipValidation"
                :class="{'shake-error': state.animation, 'blink-error': state.zipError}"
                v-maska="'#####'"
                placeholder="Enter Zip Code"
            >
            <div
                v-if="state.zipError"
                class="error-msg"
            >
              zip code is invalid
            </div>
            <button
                :disabled="state.buttonValue === 'Loading ...'"
                @click="zipValidation"
            >
              <span v-if="state.buttonValue.length === 0">
                Calculate <span class="remove-on-small-screen">Savings</span>
              </span>
              <span v-else>
                 {{ state.buttonValue }}
              </span>
              <span class="arrow"></span>
            </button>
          </div>
          <p class="secure-text-img">
            <img src="../../assets/images/lock-icon.png" alt="small-lock">
            All Inquiries are 100% Secure &amp; Confidential!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/style/main';

  .hero {
    background: url(../../assets/images/hero-bg.jpg) no-repeat right top #d6e2f0;
    height: 570px;
    .hero-header {
      width: 90%;
      color: $dark-blue;
      font-size: 48px;
      line-height: 58px;
      font-weight: 600;
      margin-top: 80px;
      text-align: left;
      font-family: $font-poppins;
    }
    .hero-savings {
      width: 750px;
      background: $dark-blue;
      margin-top: 30px;
      border-radius: 8px;
      overflow: hidden;
      font-family: $font-poppins;
      .savings-top-box {
        width: 100%;
        background: #2f77ff;
        padding: 13px 20px 17px;
        text-align: left;
        position: relative;
        .top-text {
          font-size: 23px;
          line-height: 30px;
          color: white;
          font-weight: 500;
          letter-spacing: .5px;
        }
        .bottom-text {
          color: #fff;
          font-size: 17px;
          letter-spacing: .5px;
        }
        :after {
          content: '';
          position: absolute;
          left: 30px;
          bottom: -10px;
          border-top: 10px solid #2f77ff;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
        }
      }
      .savings-bottom-box {
        width: 100%;
        padding: 25px;
        .top-box {
          display: flex;
          justify-content: space-between;
          position: relative;
          input {
            padding: 7px 15px;
            float: left;
            font-weight: normal;
            font-size: 17px;
            line-height: 24px;
            width: 49%;
            height: 60px;
            outline: none;
            background: #fff;
            border-radius: 7px;
            letter-spacing: 0.5px;
            border: 2px solid #d6d6d6;
            transition: background-color ease-in-out 0.2s;
            margin-bottom: 14px;
            &.blink-error {
              background-color: #f8cdcd;
              border: 2px #ff0000 solid;
              animation: blink 1s;
              animation-iteration-count: 4;
            }
            &.shake-error {
              animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
            }
          }
          .error-msg {
            position: absolute;
            top: 62px;
            left: 95px;
            color: #ff0014;
            font-size: 14px;
            font-weight: 500;
          }
          button {
            float: right;
            height: 60px;
            font-size: 23px;
            line-height: 54px;
            border: none;
            outline: none;
            font-weight: 600;
            color: #fff;
            width: 49%;
            cursor: pointer;
            border-radius: 7px;
            background: -moz-linear-gradient(180deg, #ff001a, #911f2b);
            background: -webkit-linear-gradient(180deg, #ff001a, #911f2b);
            background: linear-gradient(180deg, #ff001a, #911f2b);
            position: relative;
            padding-right: 45px;
            padding-bottom: 4px;
            &:hover {
              background: -moz-linear-gradient(0deg, #ff001a, #911f2b);
              background: -webkit-linear-gradient(0deg, #ff001a, #911f2b);
              background: linear-gradient(0deg, #ff001a, #911f2b);
            }
            .arrow {
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              width: 45px;
              border-left: 1px solid #921f2c;
              &:after {
                content: '';
                display: inline-block;
                vertical-align: middle;
                border-left: 10px solid #fff;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
              }
            }
          }
        }
        .secure-text-img {
          width: 100%;
          font-size: 15px;
          color: #fff;
          margin-top: 10px;
          letter-spacing: 0.5px;
          text-align: left;
          img {
            display: inline-block;
            vertical-align: top;
            margin-right: 3px;
            margin-top: 4px;
          }
        }
      }
    }
  }

  @keyframes blink { 50% { border-color:#fff ; }  }
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @media only screen and (max-width: 1900px) {
    .hero {
      background: url(../../assets/images/hero-bg.jpg) no-repeat center top #d6e2f0;
    }
  }

  @media only screen and (max-width: 1021px) {
    .hero {
      height: 550px;
      .hero-header {
        font-size: 36px;
        line-height: 46px;
      }
      .hero-savings {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .hero {
      padding: 15px 0 30px;
      height: auto;
      background: url(../../assets/images/hero-bg.jpg) no-repeat center bottom #d6e2f0;
      background-size: cover;
      .hero-header {
        margin-top: 0;
        font-size: 27px;
        line-height: 38px;
        letter-spacing: .5px;
        text-align: center;
        width: 100%;
        .hero-break {
          display: none;
        }
      }
      .hero-savings {
        margin-top: 15px;
        .savings-top-box {
          padding: 7px 15px 15px;
          .top-text {
            font-size: 19px;
          }
          .bottom-text {
            font-size: 15px;
          }
        }
        .savings-bottom-box {
          padding: 25px 15px 15px;
          .top-box {
            flex-direction: column;
            input {
              min-width: 100%;
              margin-bottom: 24px;
            }
            .error-msg {
             left: 2px;
            }
            button {
              min-width: 100%;
            }
          }
          .secure-text-img {
            font-size: 12px;
            margin-top: 20px;
            img {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 300px) {
    .hero .hero-savings .savings-bottom-box .top-box button .remove-on-small-screen {
      display: none;
    }
  }
</style>
