<script setup>
const currentYear = new Date().getFullYear().toString()
</script>

<template>
  <div class="footer">
    <div class="container">
      <img src="../../assets/images/OpenHomeSmallLogo.png" alt="Small Open Home logo">
      <div class="footer-nav">
        <a href="/terms-and-conditions/">Terms & Conditions</a> <span class="remove-line-on-mobile">|</span>
        <a href="/privacy-policy/">Privacy Policy</a> <span class="remove-line-on-mobile">|</span>
        <a href="/privacy-policy#california-resident">CA Resident</a> <span class="remove-line-on-mobile">|</span>
        <a href="/marketing-partners/">Marketing Partners</a> <span class="remove-line-on-mobile">|</span>
        <a href="/do-not-sell-my-information">Do Not Sell My Information</a>
      </div>
      <div class="footer-text">
        © Copyright {{ currentYear }} OpenHome, All rights reserved.
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/style/main';
  .footer {
    width: 100%;
    padding: 40px 0;
    background: #fff;
    font-family: $font-poppins;
    .container {
      text-align: center;
      img {
        max-width: 216px;
        filter: grayscale(100%);
      }
      .footer-text, .footer-nav {
        font-size: 17px;
        line-height: 25px;
        color: #616161;
        margin-top: 5px;
        a {
          text-decoration: none;
          color: #616161;
          padding: 0 5px;
        }
      }
    }
  }

  @media (max-width: 1021px) {
    .footer .container .footer-text, .footer-nav {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 10px;
      a {
        width: auto;
      }
      .remove-line-on-mobile {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .footer .container .footer-text, .footer-nav {
      font-size: 14px;
      line-height: 22px;
      margin: 10px auto 0;
      a {
        font-size: 14px;
      }
      .remove-line-on-mobile.first {
        display: none;
      }
    }
  }

  @media (max-width: 500px) {
    .footer .container .footer-text, .footer-nav {
      max-width: 270px;
    }
  }

  @media (max-width: 300px) {
    .footer .container {
      .footer-nav {
        a {
          display: block;
        }
        .remove-line-on-mobile {
          display: none;
        }
      }
    }
    .footer .container .footer-text, .footer-nav .remove-line-on-mobile {
      display: block;
    }
  }
</style>